<template>
  <div v-if="permissions.includes('mytasks')">
    <!--begin::Members-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label" v-if="show_back">Task for committee</h3>
          <h3 class="card-label" v-else>Task for onboarding</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <v-row justify="center" v-if="show_back">
                <a
                  href="#"
                  @click="redirectToManageMember()"
                  class="btn btn-primary font-weight-bolder mr-5"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,
                            10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  Back to manage
                </a>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
        <v-card>
            <v-card-title class="pt-0 pr-0">
            <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table :headers="headers" :items="task_list">
            <template v-slot:item="row">
                <tr>
                <td>
                  <v-checkbox
                    @click="markAsCompleted(row.item.task_id)"
                    required
                  ></v-checkbox>
                </td>
                <td>{{ row.item.task.task_type }}</td>
                <td>{{ row.item.task.task_name }}</td>
                <td>{{ row.item.is_completed ? "Completed" : "Pending" }}</td>
                <td>
                    <v-tooltip top v-if="row.item.task.task_type != 'General Task'">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="row.item.task.task_type != 'General Task'"
                          class="mx-1"
                          fab
                          small
                          v-on="on"
                          @click="viewTask(row.item)"
                        >
                          <v-icon color="#B5B5C3">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Task</span>
                    </v-tooltip>
                </td>
                </tr>
            </template>
            </v-data-table>
        </v-card>
        </div>
      </div>
    </div>
    <!--end::Tasks-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { TASK_MARK_COMPLETED } from "@/core/services/store/committee.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "membersList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      task_list: [],
      permissions: [],
      headers: [
        { text: "Mark Complete", sortable: false, value: "mark_complete", width: "10%" },
        { text: "Type", align: "left", value: "task_type", width: "20%" },
        { text: "Task", value: "task_name", width: "30%" },
        { text: "Status", value: "completed", width: "20%" },
        { text: "Action", sortable: false, value: "action", width: "20%" },
      ]
    };
  },
  watch: {
    getPermissions: function(newVal) {
      this.permissions = newVal;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig","getPermissions","getCompany"]),
    config() {
      return this.layoutConfig();
    }
  },
  props: {
    show_back: {
      type: Boolean,
      default: true
    },
    is_onboarding: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Members" }]);
    this.getTaskList();
  },
  methods: {
    downloadDoc(doc_id) {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/downloadDocument/" +
        doc_id;
      window.open(url);
    },
    downloadVideo(training_video_id) {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/downloadVideo/" +
        training_video_id;
      window.open(url);
    },
    viewTask(task) {
      if(task.task.task_type == "Training Video") {
        window.open(process.env.VUE_APP_DOC_URL + "api/download-video/" + task.task.training_video_id);
      } else {
        window.open(process.env.VUE_APP_DOC_URL + "api/download-document/" + task.task.id);
      }
    },
    redirectToManageMember() {
      let context = this;
      context.$router.go(-1); //go back to mange member
    },
    getTaskList() {
      let context = this;
      axios({
        method: "get",
        url: "my-task-list?status='Pending'",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if(result.data.tasks_list) {
            context.task_list = result.data.tasks_list;
          } else {
            Swal.fire("Error", "Error in fetching task list.", "info");
          }
        },
        function() {
          Swal.fire("Error", "Error in fetching task list.", "info");
        }
      );
    },
    markAsCompleted(task_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to mark complete this task?",
        showCancelButton: true,
        confirmButtonText: "Mark Complete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(
            TASK_MARK_COMPLETED,
            {
              task_id: task_id,
              user_id: this.$route.params.id,
              committee_id: this.$route.params.committee_id
            }
          ).then(() => {
            this.getTaskList();
          });
          Swal.fire("Task mark as  Completed!", "", "success");
        } else if (result.isDismissed) {
          Swal.fire("Task unmarked", "", "info");
        }
      });
    }
  }
};
</script>
