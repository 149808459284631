<template>
  <div>
    <!--begin::Dashboard-->
    <template>
      <div v-if="notification_list.length > 0 && !notification_list[0].read_at">
        <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          color="deep-purple accent-4"
          dark
          dismissible
        >
          <span v-html="notification_list[0].data"></span>
        </v-alert>
      </div>
    </template>
    <div class="row">
      <div class="col-xxl-12">
        <MixedWidget1 v-bind:company_type="company_type"></MixedWidget1>
      </div>
    </div>
    <div class="row mb-10" v-if="company_type == 'Chapter Organization'">
      <div class="col-xxl-12">
        <member-invitation-status></member-invitation-status>
      </div>
    </div>
    <div class="row mb-10">
      <div class="col-xxl-12">
        <important-renewal></important-renewal>
      </div>
    </div>
    <div class="row mb-10" v-if="company_type == 'Small Enterprise' && permissions.includes('training')">
      <div class="col-xxl-12">
        <training-in-progress :enroll_btn="false"></training-in-progress>
      </div>
    </div>
    <div class="row mb-10" v-if="company_type == 'Small Enterprise' && assessment_action_answers.length > 0">
      <div class="col-xxl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header pt-3 pb-3">
            <div class="card-title">
              <h3 class="card-label">Pending Tasks</h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <v-list
              two-line
              subheader
            >
              <v-subheader>Suggestions</v-subheader>
              <span
                v-for="(val,key) in assessment_action_answers"
                v-bind:key="key"
              >
                <v-list-item-content v-if="val.options">
                  <v-list-item-title class="ml-10">
                    {{ val.options.action_item_text }}
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle>{{ val }}</v-list-item-subtitle> -->
                </v-list-item-content>
              </span>
            </v-list>
          </div>
        </div>
      </div>
    </div>
    <my-task-list v-bind:show_back="false" v-bind:is_onboarding="true"></my-task-list>
    <!-- <div class="col-xxl-4">
      <ListWidget9></ListWidget9>
    </div>
    <div class="col-xxl-4">
      <StatsWidget7></StatsWidget7>
      <StatsWidget12></StatsWidget12>
    </div>

    <div class="col-xxl-4 order-1 order-xxl-1">
      <ListWidget1></ListWidget1>
    </div>
    <div class="col-xxl-8 order-2 order-xxl-1">
      <AdvancedTableWidget2></AdvancedTableWidget2>
    </div>

    <div class="col-xxl-4 order-1 order-xxl-2">
      <ListWidget3></ListWidget3>
    </div> -->
    <!-- <div class="col-xxl-12 order-1 order-xxl-2">
      <ListWidget4></ListWidget4>
    </div>
    <div class="col-lg-12 col-xxl-12 order-1 order-xxl-2">
      <ListWidget8></ListWidget8>
    </div> -->

    <!-- <div class="col-xxl-4 order-1 order-xxl-2">
      <StatsWidget13></StatsWidget13>
    </div>
    <div class="col-xxl-8 order-1 order-xxl-2">
      <AdvancedTableWidget3></AdvancedTableWidget3>
    </div> -->
    <!-- <div class="card card-custom gutter-b">
      <div class="card-body">
        <h3 style="/! margin-left: 100px; /" class="card-label mb-0">
          Comming Soon
        </h3>
      </div>
    </div> -->
    <!-- <h2 class="text-center" style="margin-left: 100px;">Comming Soon</h2> -->
    <!-- </div> -->
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import KTCodePreview from "@/view/content/CodePreview.vue";
import { GET_COMPANY } from "@/core/services/store/user.module";
import EventBus from "@/core/services/store/event-bus";
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import MemberInvitationStatus from "./components/MemberInvitationStatus.vue";
import ImportantRenewal from "./components/ImportantRenewal.vue";
import TrainingInProgress from "./training/TrainingInProgress.vue";
import Swal from "sweetalert2";
import MyTaskList from "./committee/TasksList.vue";

export default {
  name: "dashboard",
  data() {
    return {
      company_type: "Small Enterprise",
      code4: {
        html: `<div>
  <v-alert
    v-model="alert"
    border="left"
    close-text="Close Alert"
    color="deep-purple accent-4"
    dark
    dismissible
  >
    Aenean imperdiet. Quisque id odio. Cras dapibus. Pellentesque ut neque. Cras dapibus.

    Vivamus consectetuer hendrerit lacus. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur blandit mollis lacus. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo.
  </v-alert>
  <div class="text-center">
    <v-btn
      v-if="!alert"
      color="deep-purple accent-4"
      dark
      @click="alert = true"
    >
      Reset
    </v-btn>
  </div>
</div>`,
        js: `export default {
    data () {
      return {
        alert: true
      }
    },
  }`
      },
      alert: true,
      assessment_action_answers: [],
      assessment_training_answers: [],
      permissions: [],
      notification_list: []
    };
  },
  components: {
    MixedWidget1,
    MemberInvitationStatus,
    ImportantRenewal,
    TrainingInProgress,
    MyTaskList
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    getPermissions: function(newVal) {
      this.permissions = newVal;
    }
  },
  computed: {
    ...mapGetters(["getCompany"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

    //get company data
    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
    this.getActionItems();
    this.getNotificationsList();
  },
  methods: {
    getNotificationsList() {
      let context = this;
      let api_url = "notifications";
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.notification_list = result.data.result;
        },
        function(error) {
          Swal.fire(error, "", "info");
        }
      );
    },
    getActionItems() {
      let context = this;
      axios({
        method: "get",
        url: "get-pending-tasks",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.assessment_action_answers) {
            context.assessment_action_answers = result.data.assessment_action_answers;
          }
          if (result.data.assessment_training_answers) {
            context.assessment_training_answers = result.data.assessment_training_answers;
          }
        },
        function() {
          Swal.fire("Error", "Incomplete Assessment fetch error!", "error");
        }
      );
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
