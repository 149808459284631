<template>
  <div>
    <v-card>
      <v-card-title class="headline font-weight-bold font-size-h6">
        Member Invitation Status
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="member_list">
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.first_name }} {{ row.item.last_name }}</td>
              <td>{{ row.item.email }}</td>
              <td>{{ row.item.role }}</td>
              <td>
                <span v-if="row.item.employee">
                  {{
                    row.item.employee.invitation_status ? "Approved" : "Pending"
                  }}
                </span>
              </td>
              <td>
                <span v-if="row.item.employee">
                  {{
                    row.item.employee.onboarding_status ? "Approved" : "Pending"
                  }}
                </span>
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="memberEdit(row.item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Member</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="memberDelete(row.item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Member</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
import { DELETE_MEMBER } from "@/core/services/store/member.module";
import EventBus from "@/core/services/store/event-bus";

export default {
  name: "MemberInvitationStatus",
  data() {
    return {
      currentPlace: null,
      headers: [
        { text: "Name", align: "left", value: "name", width: "17%" },
        { text: "Email", sortable: false, value: "title", width: "17%" },
        {
          text: "Member Type",
          sortable: false,
          value: "organization",
          width: "17%"
        },
        {
          text: "Invitation Status",
          sortable: false,
          value: "invitation_status",
          width: "15%"
        },
        {
          text: "Onboarding Status",
          sortable: false,
          value: "onboarding_Status",
          width: "17%"
        },
        { text: "Action", sortable: false, value: "action", width: "17%" }
      ],
      member_list: []
    };
  },
  mounted() {
    let context = this;
    context.getMemberInvitationStatus();
  },
  methods: {
    memberDelete(emp_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Member!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_MEMBER, emp_id).then(() => {
            this.getMembersList();
          });
          Swal.fire("Member is Deleted!", "", "success");
        } else if (result.isDismissed) {
          Swal.fire("Member is safe.", "", "info");
        }
      });
    },
    memberEdit(emp_id) {
      this.$router.push({ name: "members.edit", params: { id: emp_id } });
    },
    getMemberInvitationStatus() {
      let context = this;
      axios({
        method: "get",
        url: "member-invitation-status",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.member_list = result.data.members;
          var pending_invite_count = 0;
          context.member_list.forEach(member => {
            if (member.employee && member.employee.invitation_status) {
              pending_invite_count++;
            }
          });
          EventBus.$emit("MEMBER_PENDING_INVITATION", pending_invite_count);
        },
        function() {
          Swal.fire("Error", "Error in fetching Member list", "info");
        }
      );
    }
  }
};
</script>
