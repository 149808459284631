<template>
  <div>
    <v-card>
      <v-card-title class="headline font-weight-bold font-size-h6">
        Training In Progress
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="enroll_btn ? headers_with_action : headers_without_action"
          :items="training_list"
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.employee_enrolled }}</td>
              <td>{{ row.item.completed_training }}</td>
              <td>{{ row.item.over_due }}</td>
              <td v-if="enroll_btn">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      small
                      v-on="on"
                      @click="enrollTraining(row.item.id)"
                    >
                      View
                    </v-btn>
                  </template>
                  <span>View For Training</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
// import EventBus from "@/core/services/store/event-bus";

export default {
  name: "TrainingInProgress",
  data() {
    return {
      currentPlace: null,
      headers_with_action: [
        { text: "Training", align: "left", value: "name", width: "20%" },
        {
          text: "Employee Enrolled",
          sortable: false,
          value: "enrolled",
          width: "20%"
        },
        {
          text: "Number Completed",
          sortable: false,
          value: "completed",
          width: "20%"
        },
        {
          text: "Number Overdue",
          sortable: false,
          value: "overdue",
          width: "20%"
        },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ],
      headers_without_action: [
        { text: "Training", align: "left", value: "name", width: "20%" },
        {
          text: "Employee Enrolled",
          sortable: false,
          value: "enrolled",
          width: "20%"
        },
        {
          text: "Number Completed",
          sortable: false,
          value: "completed",
          width: "20%"
        },
        {
          text: "Number Overdue",
          sortable: false,
          value: "overdue",
          width: "20%"
        },
      ],
      training_list: []
    };
  },
  props: {
      enroll_btn: Boolean
  },
  mounted() {
    let context = this;
    context.getTrainingInProgress();
  },
  methods: {
    enrollTraining(training_id) {
      this.$router.push({ name: "training.enroll", params: { id: training_id } });
    },
    getTrainingInProgress() {
      let context = this;
      axios({
        method: "get",
        url: "training-in-progress",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.training_list = result.data.training_in_progress;
        },
        function() {
          Swal.fire("Error", "Error in fetching training in progress list", "info");
        }
      );
    }
  }
};
</script>
