<template>
  <!--begin::Mixed Widget 1-->
  <div class="card card-custom card-stretch gutter-b" style="background-color: transparent;box-shadow: none;">
    <!--begin::Header-->
    <!-- <div class="card-header border-0 bg-danger py-5">
      <h3 class="card-title font-weight-bolder text-white">
        Sales Stat
      </h3>
      <div class="card-toolbar">
        <Dropdown1></Dropdown1>
      </div>
    </div> -->
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0 position-relative overflow-hidden">
      <!--begin::Chart-->
      <!-- <apexchart
        class="card-rounded-bottom bg-danger"
        :options="chartOptions"
        :series="series"
        type="area"
      ></apexchart> -->
      <!--end::Chart-->
      <!--begin::Stats-->
      <div style="padding: 0 !important;" class="card-spacer mt-6" v-if="company_type == 'Small Enterprise'">
        <!--begin::Row-->
        <div class="row">
          <!-- Box One -->
          <div class="col-lg-6" v-if="is_company_admin == 1">
            <!--begin::Callout-->
            <div class="card card-custom wave wave-animate-slow wave-primary mb-8 mb-lg-0">
              <div class="card-body">
                <div class="d-flex align-items-center p-5">
                  <!--begin::Icon-->
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
                    </span>
                  </div>
                  <!--end::Icon-->
                  <!--begin::Content-->
                  <div class="d-flex flex-column">
                      <p class="text-dark font-weight-bold font-size-h4 mb-1">
                      <!-- {{ risk_score }} -->
                      NA
                      </p>
                      <a href="#/assessments" class="text-dark-75 text-hover-primary">
                      Risk Score / Compliance-Risk Program Stage
                      </a>
                  </div>
                  <!--end::Content-->
                </div>
              </div>
            </div>
            <!--end::Callout-->
          </div>
          <!-- Box One -->

          <!-- Box two -->
          <div class="col-lg-6" v-if="is_company_admin == 1">
            <!--begin::Callout-->
            <div class="card card-custom wave wave-animate-slow wave-danger mb-8 mb-lg-0">
              <div class="card-body">
                <div class="d-flex align-items-center p-5">
                  <!--begin::Icon-->
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-danger svg-icon-4x">
                      <inline-svg src="media/svg/icons/Home/Box.svg" />
                    </span>
                  </div>
                  <!--end::Icon-->
                  <!--begin::Content-->
                  <div class="d-flex flex-column">
                    <p class="text-dark font-weight-bold font-size-h4 mb-1">
                      {{ pending_assessment_count }}
                    </p>
                    <a href="#" class="text-dark-75 text-hover-primary">
                      Pending Risk Assessments
                    </a>
                  </div>
                  <!--end::Content-->
                </div>
              </div>
            </div>
            <!--end::Callout-->
          </div>
          <!-- Box two -->

          <!-- Box three -->
				  <div class="col-lg-6">
            <!--begin::Callout-->
            <div class="card card-custom wave wave-animate-slow wave-warning mb-8 mb-lg-0">
              <div class="card-body">
                <div class="d-flex align-items-center p-5">
                  <!--begin::Icon-->
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-warning svg-icon-4x">
                      <inline-svg src="media/svg/icons/Clothes/Briefcase.svg" />
                    </span>
                  </div>
                  <!--end::Icon-->
                  <!--begin::Content-->
                  <div class="d-flex flex-column">
                    <p class="text-dark font-weight-bold font-size-h4 mb-1">
                      {{ ba_count }}
                    </p>
                    <a href="#/business-account" class="text-dark-75 text-hover-primary">
                      Business Accounts
                    </a>
                  </div>
                  <!--end::Content-->
                </div>
              </div>
            </div>
            <!--end::Callout-->
				  </div>
          <!-- Box three -->

          <!-- Box four -->
          <div class="col-lg-6">
            <!--begin::Callout-->
            <div class="card card-custom wave wave-animate-slow wave-success mb-8 mb-lg-0">
              <div class="card-body">
                <div class="d-flex align-items-center p-5">
                  <!--begin::Icon-->
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-success svg-icon-4x">
                      <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg" />
                    </span>
                  </div>
                  <!--end::Icon-->
                  <!--begin::Content-->
                  <div class="d-flex flex-column">
                    <p class="text-dark font-weight-bold font-size-h4 mb-1">
                      {{ doc_count }}
                    </p>
                    <a href="#/documents" class="text-dark-75 text-hover-primary">
                      Documents
                    </a>
                  </div>
                  <!--end::Content-->
                </div>
              </div>
            </div>
            <!--end::Callout-->
          </div>
          <!-- Box four -->
        </div>
      </div>
        
      <div v-else style="padding: 0 !important;" class="card-spacer mt-6">
        <div class="row">
          <!-- Box One -->
          <div class="col-lg-6">
              <!--begin::Callout-->
              <div class="card card-custom wave wave-animate-slow wave-primary mb-8 mb-lg-0">
                <div class="card-body">
                  <div class="d-flex align-items-center p-5">
                    <!--begin::Icon-->
                    <div class="mr-6">
                      <span class="svg-icon svg-icon-primary svg-icon-4x">
                        <inline-svg src="media/svg/icons/Clothes/Briefcase.svg" />
                      </span>
                    </div>
                    <!--end::Icon-->
                    <!--begin::Content-->
                    <div class="d-flex flex-column">
                      <p class="text-dark font-weight-bold font-size-h4 mb-1">{{ ba_count }}</p>
                      <a href="#/business-account" class="text-dark-75 text-hover-primary">
                      Business Accounts
                      </a>
                    </div>
                    <!--end::Content-->
                  </div>
                </div>
              </div>
              <!--end::Callout-->
          </div>
          <!-- Box One -->

          <!-- Box two -->
          <div class="col-lg-6">
            <!--begin::Callout-->
              <div class="card card-custom wave wave-animate-slow wave-danger mb-8 mb-lg-0">
                <div class="card-body">
                  <div class="d-flex align-items-center p-5">
                    <!--begin::Icon-->
                    <div class="mr-6">
                      <span class="svg-icon svg-icon-danger svg-icon-4x">
                      <inline-svg src="media/svg/icons/Code/Time-schedule.svg" />
                      </span>
                    </div>
                    <!--end::Icon-->
                    <!--begin::Content-->
                    <div class="d-flex flex-column">
                      <p class="text-dark font-weight-bold font-size-h4 mb-1">{{ pending_invitation_count }}</p>
                      <a href="#" class="text-dark-75 text-hover-primary">
                      Member Invitation Pending
                      </a>
                    </div>
                    <!--end::Content-->
                  </div>
                </div>
              </div>
            <!--end::Callout-->
          </div>
          <!-- Box two -->

          <!-- Box three -->
          <div class="col-lg-6">
            <!--begin::Callout-->
              <div class="card card-custom wave wave-animate-slow wave-warning mb-8 mb-lg-0">
                <div class="card-body">
                  <div class="d-flex align-items-center p-5">
                    <!--begin::Icon-->
                    <div class="mr-6">
                      <span class="svg-icon svg-icon-warning svg-icon-4x">
                        <inline-svg src="media/svg/icons/Shopping/Wallet.svg" />
                      </span>
                    </div>
                    <!--end::Icon-->
                    <!--begin::Content-->
                    <div class="d-flex flex-column">
                      <p class="text-dark font-weight-bold font-size-h4 mb-1">NA</p>
                      <a href="#" class="text-dark-75 text-hover-primary">
                      Dues/Fees to Collect 
                      </a>
                    </div>
                    <!--end::Content-->
                  </div>
                </div>
              </div>
            <!--end::Callout-->
          </div>
          <!-- Box three -->

          <!-- Box four -->
          <div class="col-lg-6">
            <!--begin::Callout-->
              <div class="card card-custom wave wave-animate-slow wave-success mb-8 mb-lg-0">
                <div class="card-body">
                  <div class="d-flex align-items-center p-5">
                    <!--begin::Icon-->
                    <div class="mr-6">
                      <span class="svg-icon svg-icon-success svg-icon-4x">
                        <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg" />
                      </span>
                    </div>
                    <!--end::Icon-->
                    <!--begin::Content-->
                    <div class="d-flex flex-column">
                      <p class="text-dark font-weight-bold font-size-h4 mb-1">{{ doc_renewal }}</p>
                      <a href="#" class="text-dark-75 text-hover-primary">
                      Upcomming Doc Renewal
                      </a>
                    </div>
                    <!--end::Content-->
                  </div>
                </div>
              </div>
            <!--end::Callout-->
          </div>
          <!-- Box four -->
        </div>
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
  </div>
</template>

<script>
//import Dropdown1 from "@/view/content/dropdown/Dropdown1.vue";
import { mapGetters } from "vuex";
import EventBus from "@/core/services/store/event-bus";
import { GET_BUSINESS_ACCOUNTS } from "@/core/services/store/businessaccount.module";
import { GET_DOCUMENT_LIST } from "@/core/services/store/document.module";
import {
  GET_ASSESSMENTS,
  GET_ASSESSMENT_SCORE
} from "@/core/services/store/assessment.module";

export default {
  name: "widget-1",
  components: {
    // Dropdown1
  },
  props: {
    company_type: String
  },
  data() {
    return {
      doc_renewal: 0,
      pending_invitation_count: 0,
      ba_count: 0,
      doc_count: 0,
      pending_assessment_count: 0,
      risk_score: "NA",
      strokeColor: "#D13647",
      chartOptions: {},
      series: [
        {
          name: "Net Profit",
          data: [30, 45, 32, 70, 40, 40, 40]
        }
      ],
      is_company_admin : 2,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    let context = this;
    if (context.company_type == "Small Enterprise") {
      this.$store.dispatch(GET_DOCUMENT_LIST);
      this.$store.dispatch(GET_ASSESSMENTS);
      this.$store.dispatch(GET_BUSINESS_ACCOUNTS);
      this.$store.dispatch(GET_ASSESSMENT_SCORE);
    }

    EventBus.$on("RENEWAL_DOCUMENT_COUNT", function(payLoad) {
      context.doc_renewal = payLoad;
    });
    EventBus.$on("MEMBER_PENDING_INVITATION", function(payLoad) {
      context.pending_invitation_count = payLoad;
    });
    EventBus.$on("BA_COUNT", function(payLoad) {
      context.ba_count = payLoad;
    });
    EventBus.$on("DOCUMENTS_COUNT", function(payLoad) {
      context.doc_count = payLoad;
    });
    EventBus.$on("ASSESSMENT_LIST", function(payLoad) {
      context.pending_assessment_count = Object.keys(payLoad).length;
    });
    EventBus.$on("SET_ASSESSMENT_SCORE", function(payLoad) {
      context.risk_score = payLoad.toFixed(0);
    });
    EventBus.$on("GET_IS_COMPANY_ADMIN", function(newVal) {
      context.is_company_admin = newVal;
    });
    // reference; kt_mixed_widget_1_chart
    this.chartOptions = {
      chart: {
        type: "area",
        height: 200,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: this.strokeColor,
          opacity: 0.5
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 0
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.strokeColor]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return "$" + val + " thousands";
          }
        },
        marker: {
          show: false
        }
      },
      colors: ["transparent"],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.danger")],
        strokeColor: [this.strokeColor],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
  }
};
</script>
