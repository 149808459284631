<template>
  <div>
    <v-card>
      <v-card-title class="headline font-weight-bold font-size-h6">
        Important Renewals
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="document_list">
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.name }}</td>
              <td>{{ row.item.renewal_date }}</td>
              <td>
                <v-tooltip top v-if="user_permissions.includes('chapter_renewal_reminder') || user_permissions.includes('se_renewal_reminder')">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      v-on="on"
                      @click="documentRecycle(row.item.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-recycle</v-icon>
                    </v-btn>
                  </template>
                  <span>Renew Document</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { GET_COMPANY } from "@/core/services/store/user.module";

export default {
  name: "MemberInvitationStatus",
  data() {
    return {
      user_permissions: [],
      currentPlace: null,
      headers: [
        { text: "Document", align: "left", value: "name", width: "50%" },
        {
          text: "Renewal Date",
          sortable: false,
          value: "renew_date",
          width: "25%"
        },
        { text: "Action", sortable: false, value: "action", width: "25%" }
      ],
      document_list: []
    };
  },
  computed: {
    ...mapGetters(["getUserPermissions"]),
  },
  mounted() {
    let context = this;
    context.getRenewalDocs();
    this.$store.dispatch(GET_COMPANY);
  },
  watch: {
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  methods: {
    documentRecycle(doc_id) {
      this.$router.push({ name: "documents.renew", params: { id: doc_id } });
    },
    getRenewalDocs() {
      let context = this;
      axios({
        method: "get",
        url: "renewal-documents",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.document_list = result.data.documents;
          EventBus.$emit(
            "RENEWAL_DOCUMENT_COUNT",
            context.document_list.length
          );
        },
        function() {
          Swal.fire("Error", "Error in fetching documents list", "info");
        }
      );
    }
  }
};
</script>
